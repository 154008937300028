import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

const setBodyScrollLock = (instance: ReturnType<typeof useCurrentInstance>) => {
  nextTick(() => {
    const wrappers = instance.$el.querySelectorAll('.is-scrollable')
    const content = wrappers.length ? wrappers[wrappers.length - 1] : (instance.$refs.content as Element)
    if (content) {
      disableBodyScroll(content, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => el === content
      })
    }
  })
}

const keydownHandler = (callback?: () => void) => (e: KeyboardEvent) => {
  if ((e.key === 'Escape' || e.key === 'Esc') && callback) {
    callback()
  }
}

const setStopKeydownHandler = (callback?: () => void) => {
  if (!callback) {
    return null
  }

  return addEventListenerUtil({ target: document, type: 'keydown', handler: keydownHandler(callback) })
}

export function useBodyScrollLock<T extends { visible: boolean }>(
  props: T,
  instance: ReturnType<typeof useCurrentInstance>,
  callback?: () => void,
  isChangeLevel = false
) {
  let stopKeydownHandler: ReturnType<typeof addEventListenerUtil> | null = null
  watch(
    () => props.visible,
    (value) => {
      if (process.client) {
        if (value) {
          setBodyScrollLock(instance)
          stopKeydownHandler = setStopKeydownHandler(callback)
          return
        }

        clearAllBodyScrollLocks()
        if (stopKeydownHandler) {
          stopKeydownHandler()
        }
      }
    }
  )

  if (isChangeLevel && process.client) {
    setBodyScrollLock(instance)
    stopKeydownHandler = setStopKeydownHandler(callback)
  }

  if (!isChangeLevel) {
    onBeforeUnmount(() => {
      if (props.visible) {
        clearAllBodyScrollLocks()
      }
    })
  }
}
