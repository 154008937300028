<template>
  <section
    v-if="visible"
    v-e2e="'popup'"
    class="asf-modal"
    :class="[{ 'is-open': visible }, className]"
    aria-labelledby="modal-title"
  >
    <AsfOverlay v-if="overlay" visible />
    <div
      v-if="visible"
      ref="content"
      v-focus-trap="useFocusTrap"
      v-click-outside="closeEventHandler"
      class="asf-modal__container"
      @keydown.esc="closeEventHandler"
    >
      <div class="asf-modal__bar" :class="{ 'm-secondary': title }">
        <AsfButton
          ref="closeBtnRef"
          v-e2e="'close-popup'"
          class="asf-modal__close"
          :aria-label="`${$t('global.close')}`"
          @click="closeEventHandler"
        >
          <AsfIcon name="close" size="5" aria-hidden="true" :title="$t('action.close')" />
        </AsfButton>
        <slot name="content-title" :class-names="['asf-modal__title']">
          <AsfHeading v-if="title" tag="h2" appearance="h3" id="modal-title" class="asf-modal__title">
            {{ title }}
          </AsfHeading>
        </slot>
      </div>
      <div ref="body" class="asf-modal__content">
        <!--@slot Main content inside the modal.-->
        <slot />
      </div>
      <div id="modal-footer" ref="footer" v-if="$slots['content-footer']" class="asf-modal__footer">
        <!--@slot Use this slot to place footer content inside the modal.-->
        <slot name="content-footer" />
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import type { AsfModalProps } from '@ui/types'
import type { AsfButton } from '#components'
const props = withDefaults(defineProps<AsfModalProps>(), {
  title: '',
  overlay: true,
  visible: false,
  useFocusTrap: true,
  appearance: 'sm'
})
const emit = defineEmits(['close'])
const closeBtnRef = ref<InstanceType<typeof AsfButton> | null>(null)
const currentInstance = useCurrentInstance()
const closeEventHandler = () => emit('close')

useBodyScrollLock(props, currentInstance, closeEventHandler)

const className = computed(() => {
  const classNameType = `m-${props.appearance}`

  return [classNameType]
})

onMounted(() => setTimeout(() => (closeBtnRef.value?.$el as HTMLElement)?.focus(), 100))
</script>
<style lang="postcss">
@import '@components/molecules/Modal/Modal.css';
</style>
